
import { Vue, Component, Prop } from "nuxt-property-decorator";

@Component({})
export default class ViewAvatarUser extends Vue {
  @Prop({ type: String, required: true, default: "" }) avatar!: string;
  // @Prop({
  //   type: String,
  //   required: false,
  //   default: "",
  //   validator: (value) => !value || value === "lazy",
  // })
  // mode!: string;

  get avatarUser() {
    return this.avatar || "/img/avatar_anonymous.jpg";
  }

  // get isLazyLoad() {
  //   // if (!this.mode || !this.$auth.user?.property_id) {
  //   //   return false;
  //   // }
  //   return this.mode === "lazy";
  // }
  get renderObject() {
    return { attrs: { alt: "", format: "webp", width: "40", loading: "lazy" } };
    // return !this.isLazyLoad
    //   ? { attrs: { alt: "", format: "webp", width: "40", loading: "lazy" } }
    //   : { directives: [{ name: "lazy", value: this.avatarUser }] };
  }
  render(createElement) {
    const myImageVNode = createElement("nuxt-img", {
      ...this.renderObject,
      props: {
        src: this.avatarUser
      },
      class: {
        "featured-image": true,
        // 'img-fluid': true,
        "position-absolute": true,
        // 'opacity-0': !this.avatar
      },
    });
    return createElement(
      "div",
      {
        class: {
          wrapper_avatar: true,
          "custom-object-fit": this.$isIE,
          "text-center": true,
          "position-relative": true,
        },
        style: {
          backgroundImage: this.$isIE ? `url(${this.avatarUser})` : null,
          width: this.$attrs.width || "auto",
          height: this.$attrs.height || "auto",
        },
      },
      [myImageVNode]
    );
  }
}
