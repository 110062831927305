
import { Vue, Component, Prop, Watch } from "nuxt-property-decorator";
import axios from "axios";

@Component({})
export default class CardPreviewLink extends Vue {
    @Prop({type: String, required: false, default: ""}) previewUrl;
    preview = {
      image: '',
      title: '',
      description: '',
      site_name: '',
      url: ''
    };
    arrUrl: any;

    @Watch('previewUrl', {immediate: true})
    changLink(newVal: string, oldVal: string) {
      if (!newVal) {
        return;
      }
      this.arrUrl = newVal.match(/((https?:\/\/[^\s]+)|((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?))/gm);
      if (!this.arrUrl) {
        return;
      }
      const _parseUrl = `https://script.google.com/macros/s/AKfycbwoHNb0E51Rw7E1C20w1AE-3xUAWoJkzERTapvTcZp-ozvElMA/exec?url=${this.arrUrl[0]}`
      axios.get(_parseUrl).then(res => {
          const _arrMetaTags = this.getMetaTags(res.data);
          _arrMetaTags.forEach(_el => {
              const _arrContentTags = this.getTagContents(_el);
              let prop = '';
              let content = ''
              _arrContentTags?.forEach(__el => {
                  if (__el.indexOf("content=") === -1) {
                      prop = this.getTagProperty(__el);
                  } else {
                      content = this.getTagContent(__el);
                  }
                  if (prop) {
                    content = prop === 'image' ? content.replace(/&amp;/g, '&') : content;
                    this.preview[prop] = content;
                  }
              });
          });
          this.$emit('scroll-to-view');
      });
    }
  getMetaTags(site) {
    const urlRegex = /<meta(?: [^>]+)?>/gi;
    let metaTags = site.match(urlRegex);
    if (metaTags) {
      const tags = metaTags.map(tag => {
        return tag
          .replace(/\/>/g, "")
          .replace(/[\n\r]/g, "")
          .replace(/<meta /, "");
      });
      return tags;
    }
    return [];
  }
  getTagProperty(element) {
    const tags = ["description", "title", "image", "site_name", "url"];
    let propName = element
      .replace('property="og:', "")
      .replace("property='og:", "")
      .replace('name="', "")
      .replace("name='", "");
    return tags.indexOf(propName) >= 0 ? propName : '';
  }
  getTagContent(element) {
    return element.replace('content="', "").replace("content='", "");
  }
  getTagContents(tag) {
    const tagNames = /(name=".+?(?="))|(property=".+?(?="))|(content=".+?(?="))|(name='.+?(?='))|(property='.+?(?='))|(content='.+?(?='))/g;
    return tag.match(tagNames);
  }
  get getUrl() {
    let url = '';
      if ((this.arrUrl[0]).startsWith("http://") || (this.arrUrl[0]).startsWith("https://")) {
        url = this.arrUrl[0];
      } else {
        url = `http://${this.arrUrl[0]}`;
      }
      return url
  }
}
