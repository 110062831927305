var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.preview.image || _vm.preview.site_name || _vm.preview.description
    ? _c(
        "a",
        {
          staticClass: "wrapper-preview d-flex",
          attrs: { href: _vm.getUrl, target: "_blank" },
        },
        [
          _c("div", { staticClass: "cover-background" }, [
            _c("div", {
              staticClass: "img",
              style: { backgroundImage: "url(" + _vm.preview.image + ")" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "img-overlay" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "m-2" }, [
            _vm.preview.site_name
              ? _c("div", { staticClass: "site_name truncate_text mb-1" }, [
                  _vm._v(_vm._s(_vm.preview.site_name)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "description truncate_text" }, [
              _vm._v(_vm._s(_vm.preview.description)),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }